<template>
  <div id="pageContainer">
  </div>
</template>

<script>
import { GlobalWorkerOptions, getDocument } from "pdfjs-dist/legacy/build/pdf";
import PDFJSWorker from "pdfjs-dist/legacy/build/pdf.worker.entry";
GlobalWorkerOptions.workerSrc = PDFJSWorker;

let container = null;

export default {
  props: {
    src: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      pdfDocument: null,
    };
  },
  mounted() {
    this.getPdfDocument();
  },
  methods: {
    async getPdfDocument() {
      if (this.src === "data:application/pdf;base64,undefined")
        return console.error("pdf src is currupt:", this.src);

      try {
        getDocument(this.src).promise.then(
          (pdf) => {
            const numPages = pdf.numPages;
            container = document.getElementById("pageContainer");
            this.pdfDocument = pdf;
            for (let i = 1; i <= numPages; i++) {
              this.getPage(i);
            }
          },
          (reason) => {
            console.error(reason);
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
    getPage(pageNum) {
      try {
        this.pdfDocument.getPage(pageNum).then((page) => {
          let viewport = page.getViewport({ scale: 1 });

          let canvas = document.createElement("canvas");
          canvas.style.display = "block";
          let context = canvas.getContext("2d");
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          let renderContext = {
            canvasContext: context,
            viewport: viewport,
          };

          page.render(renderContext);
          if (container) {
            container.appendChild(canvas);
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style src="pdfjs-dist/web/pdf_viewer.css"></style>
<style>
#pageContainer {
  width: 100%;
  text-align: center;
}
#pageContainer canvas {
  border: 1px solid #e3e3e3;
  display: inline !important;
}
</style>
